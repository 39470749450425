import React from "react";

import HeroPost from "../components/Blog/Post/HeroPost";
import Post from "../components/Blog/Post/Post";
import Layout from "../components/Layout/layout";
import { SEO } from "../components/SEO/SEO";
import Tweets from "../components/Slider/Twitter/Tweets";

const TipsForAll = () => {
  return (
    <Layout>
      <HeroPost />
      <Post />
      <Tweets />
    </Layout>
  );
};

export default TipsForAll;

export const Head = () => (
  <SEO
    title={"Consejos para ciclistas al circular en carretera con conductores"}
    description={
      "Descubre las principales normas y recomendaciones de la Ley de Seguridad Vial para ciclistas, claves para incrementar la seguridad en carretera"
    }
    canonical={
      "consejos-carretera-ciclistas-conductores/"
    }
    image={"assets/thumbnail-post.jpeg"}
  />
);
