import "./hero-post.scss";

import * as React from "react";

const HeroPost: React.FC = () => {
  return (
    <section className={"o-hero-post"}>
      <div className={"c-hero-post"}>
        <h1 className={"a-title"}> Consejos para ciclistas al circular en carretera con conductores</h1>
      </div>
    </section>
  );
};

export default HeroPost;
