import "./post.scss";

import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const Post: React.FC = () => {
  return (
    <main className={"o-post"}>
      <div className={"c-post"}>
        <StaticImage
          className={"m-post-image"}
          imgClassName={"post-image"}
          src={"../../../assets/images/post-photo.jpeg"}
          alt={"Fotografía"}
          width={500}
        />
        <p>
          Cada vez más personas utilizan bicicletas para trasladarse hacia sus respectivos destinos o practicar deporte.
          En este sentido,{" "}
          <strong> la Ley de Seguridad Vial establece una serie de normas y recomendaciones para ciclistas,</strong> de
          especial relevancia a la hora de transitar por carretera.
        </p>
        <p>
          Son diversos los beneficios del uso de la bicicleta, como una mejor salud física y mental, ahorro económico y
          la posibilidad de disminuir la huella de carbono, algo indispensable para contrarrestar los efectos del cambio
          climático y, así, tener una mejor relación con el medioambiente.
        </p>

        <p>
          Por esto, cada vez son más las personas que han optado por utilizar este medio de transporte en los últimos
          años: según un informe de la Asociación de Marcas y Bicicletas de España (AMBE),{" "}
          <a
            href="http://asociacionambe.com/wp-content/uploads/2022/04/Datos-2021_AMBE_Final.pdf"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}>
            el volumen de venta de bicicletas alcanzó los 2.887,89 millones de euros en el año 2021, un 10,76% superior
            respecto del 2020.
          </a>
        </p>
        <h2 className={"a-subtitle"}>¿Cómo transitar en bicicleta de forma segura en carretera?</h2>
        <p>
          Durante el 2020, se registraron{" "}
          <a
            href="https://elpais.com/espana/2022-05-12/el-drama-de-los-200-ciclistas-muertos-en-accidentes-en-cuatro-anos-en-espana.html"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}>
            {" "}
            5.499 ciclistas accidentados en el país.
          </a>{" "}
          Este dato muestra la importancia de la <strong> educación vial </strong> para disminuir los siniestros que
          involucran a personas en bicicleta, ya que muchas veces -ya sea por desconocimiento o falta de carriles bici-
          transitan junto a los automovilistas por las carreteras del país.
        </p>
        <p>
          Para una sana convivencia vial y disminuir los riesgos al pedalear en carretera, a continuación, encontrarás
          algunos de los principales{" "}
          <a
            href="https://www.dgt.es/comunicacion/noticias/20-normas-que-todo-ciclista-debe-conocer/"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}>
            {" "}
            consejos y normas que deben seguir los ciclistas:
          </a>
        </p>
        <h3 className={"a-subtitle"}>Usar luces y accesorios reflectantes</h3>
        <p>
          De igual forma, es indispensable <strong> usar luces delanteras y traseras,</strong> especialmente si vas a
          pedalear por carreteras junto a vehículos. De esta manera, podrán advertirte a tiempo, evitando posibles
          accidentes.
        </p>
        <p>
          Sobre todo, en las noches, la visibilidad de los conductores de vehículos motorizados puede verse mermada si
          no existe una iluminación adecuada en la carretera, aumentando el riesgo de pasar a llevar a un ciclista.
        </p>
        <p>
          Por eso, además de las luces, es clave usar <strong> siempre chaleco reflectante,</strong> especialmente en
          días en los que la visibilidad se ve limitada (por neblina o lluvias), al igual que durante la noche.
        </p>
        <h3 className={"a-subtitle"}>No usar teléfono móvil y audífonos</h3>
        <p>
          El uso de dispositivos como el teléfono móvil y los audífonos está absolutamente{" "}
          <strong> prohibido por la Ley de Seguridad Vial,</strong> ya que puede afectar su concentración y capacidad
          auditiva mientras recorren las vías interurbanas.
        </p>
        <h3 className={"a-subtitle"}>Señalizar las maniobras</h3>
        <p>Algunas de las más importantes son:</p>
        <ul>
          <li>
            <strong> Al iniciar la marcha,</strong> deben verificar que la vía esté lo suficientemente despejada para
            integrarse a la pista.
          </li>
          <li>
            {" "}
            <strong> Ante un paso de peatones,</strong> los ciclistas deben esperar que termine de pasar el flujo de
            personas.
          </li>
          <li>
            De igual forma, los ciclistas tienen <strong> prioridad al circular por un carril bici </strong> respecto a
            los vehículos motorizados.
          </li>
        </ul>
        <h3 className={"a-subtitle"}>Máximo un pasajero, de hasta 7 años</h3>
        <p>
          Las normas de circulación establecen que los ciclistas mayores de edad pueden transportar solo a un pasajero,
          de 7 años como máximo, en un <strong> asiento adicional homologado.</strong>
        </p>
        <h3 className={"a-subtitle"}>Evitar el alcohol</h3>
        <p>
          El alcohol también merma el rendimiento de los conductores que andan en bicicleta, por tanto, deben evitar su
          consumo.
        </p>
        <p>
          Al respecto, la ley establece{" "}
          <a
            href="https://www.dgt.es/muevete-con-seguridad/evita-conductas-de-riesgo/consumo-de-alcohol/"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}>
            {" "}
            límites respecto de la ingesta alcohólica en ciclistas:
          </a>
        </p>
        <ul>
          <li>0,25 miligramos por litro (mg/l), en aire aspirado.</li>
          <li>0,5 gramos por litro (g/l), en el caso de alcohol en sangre.</li>
        </ul>
        <p>
          Evitar el consumo de alcohol es una de las principales medidas de seguridad al pedalear, especialmente a la
          hora de circular junto a vehículos, ya que solo así podrás{" "}
          <strong> reaccionar oportunamente ante dificultades en la ruta </strong> o la imprudencia de conductores.
        </p>
        <h3 className={"a-subtitle"}>Marcha en paralelo</h3>
        <p>
          Al transitar por carretera, los ciclistas tienen autorización para marchar en filas de dos, acercándose lo
          máximo posible a la derecha de la vía. Asimismo, si durante el trayecto hay un carril bici disponible, es
          recomendable transitar por ese carril exclusivo para bicicletas.
        </p>
        <p>
          Así, Movistar promueve la campaña #RodandoJuntos con el objetivo de reducir el número de accidentes en
          carretera y poder concienciar sobre la seguridad en la vía para ciclistas y conductores.
        </p>
      </div>
    </main>
  );
};

export default Post;
