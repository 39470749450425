import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

interface Props {
  title?: string;
  description?: string;
  location?: string;
  robots?: string;
  canonical?: string;
  image?: string;
}

export const SEO: (props: Props) => JSX.Element = (props: Props) => {
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          email
          foundingDate
          siteUrl
          instagram
          facebook
          twitter
          google
          linkedin
        }
      }
    }
  `);
  const { siteMetadata } = site;

  const title = props.title !== undefined ? props.title : siteMetadata.title;
  const description = props.description !== undefined ? props.description : siteMetadata.description;
  const robots = props.robots !== undefined ? props.robots : "index, follow";
  const getCanonical = props.canonical !== undefined ? `/${props.canonical}/` : "/";
  const image = props.image !== undefined ? props.image : "assets/thumbnail.jpg"; //statics

  const canonicalComposition = `${siteMetadata.siteUrl}/${getCanonical}`;

  const canonical = canonicalComposition.replace(/[\\/]{2,}/g, "/").replace("https:/", "https://");

  return (
    <>
      <meta
        name="description"
        content={description}
      />
      <meta
        name="image"
        content={`${siteMetadata.siteUrl}/${image}`}
      />

      <meta
        property="og:url"
        content={`${canonical}`}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:image"
        content={`${siteMetadata.siteUrl}/${image}`}
      />
      <meta
        property="fb:app_id"
        content={siteMetadata.facebook}
      />

      <meta
        name="twitter:card"
        content="summary"
      />
      <meta
        name="twitter:creator"
        content={siteMetadata.twitter}
      />
      <meta
        name="twitter:site"
        content={siteMetadata.twitter}
      />
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content={description}
      />
      <meta
        name="twitter:image:src"
        content={`${siteMetadata.siteUrl}/${image}`}
      />
      <link
        rel="publisher"
        href={siteMetadata.google}
      />
      <link
        rel="canonical"
        href={`${canonical}`}
      />
      <title>{title}</title>
      <html
        lang="es"
        dir="ltr"
      />
      <meta
        name="robots"
        content={robots}
      />
    </>
  );
};
